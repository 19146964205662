.visual-list {
  display: flex;
  margin-bottom: 6rem;

  h4 {
    margin-top: -0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .image {
    flex-shrink: 0;
    width: 8rem;

    p {
      margin-top: 0.5rem;
      text-align: center;
    }
  }

  .progressive-image {
    margin-top: 0;
  }

  .copy {
    flex: 1;
    margin-left: 4rem;
    min-width: 0;
  }

  &.large {
    .image {
      width: 16rem;
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .visual-list {
    display: block;

    .image {
      float: left;
      margin: 0 1.5rem 1.5rem 0;
      width: 5rem;
    }

    h4 {
      @include clearfix;
    }

    .copy {
      margin-left: 0;
    }

    &.large {
      .image {
        float: none;
        width: 100%;
      }
    }
  }
}
