.button,
%button {
  @extend %h5;
  background-color: $lighter-gray;
  border: 0;
  color: $black;
  display: inline-block;
  flex-grow: 0;
  font-weight: $bold;
  padding: 0.75rem 2rem;
  text-decoration: none;
  user-select: none;

  &:hover {
    border: 0;
    border-color: $black;
    color: $black;
    cursor: pointer;
    text-decoration: none;
  }

  &:not(.no-arrow) {
    &::after {
      content: "\2192";
      display: inline-block;
      padding-left: 1rem;
      transform: translate3d(-0.5rem, 0, 0);
      transition: 0.3s ease transform;
    }

    &:hover::after {
      transform: none;
    }
  }
}

.text-button {
  @extend %button;
  background-color: $transparent;
  color: $lightest-text-gray;
  padding: 0;
}