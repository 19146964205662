header {
  padding: 8rem 0 4rem;
}

.with-hero-style-full_bleed header {
  padding: 4rem 0;
  position: absolute;
  top: 0;
  z-index: 100;

  span, a {
    // scss-lint:disable ImportantRule
    color: $white;

    &:hover {
      color: $light-gray;
      text-decoration-color: $light-gray;
    }
  }
}

footer {
  position: relative;
  z-index: 100;

  .footer-wrapper {
    color: $lightest-text-gray;
    display: flex;
    justify-content: space-between;
    margin: -3rem -1.5rem 0;
    padding: 6rem 0;

    .about-wrapper {
      padding: 0 1.5rem;
      width: 50%;
    }

    .links-wrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .links,
    .pages {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 0 1.5rem;
    }

    h3 {
      @extend %p;
      margin-bottom: 0.5rem;
    }

    h2 {
      line-height: 2rem;
    }

    a {
      color: $lightest-text-gray;
      transition: 0.3s ease color;
    }
  }

  &:hover .footer-wrapper {
    a {
      color: $black;

      &:hover {
        color: $light-gray;
      }
    }
  }
}

.path {
  margin-bottom: 2rem;
  user-select: none;

  .component {
    user-select: auto;
  }

  .tilde {
    user-select: none;
  }

  .slash {
    padding: 0 0.5rem;
    user-select: none;
  }

  a {
    @extend %subtle-link;
    text-decoration-color: transparent;

    &:hover {
      text-decoration-color: $medium-gray;
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .path {
    margin-bottom: 1rem;
  }

  footer .footer-wrapper {
    .about-wrapper {
      width: 33%;
    }

    .links-wrapper {
      width: 66%;
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  footer .footer-wrapper {
    flex-wrap: wrap;
    margin: -2rem -1rem 0;

    .links,
    .pages {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .about-wrapper {
      margin-bottom: 3rem;
      width: 100%;
    }

    .links-wrapper {
      width: 100%;
    }
  }

  .m-break {
    @include clearfix;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .with-hero-style-full_bleed header {
    padding: 1rem 0;
  }
}