@use "sass:math";

$content-width: 74rem;
$text-width: 48rem;
$v-lg-rem: 6;
// scss-lint:disable Syntax
$v-md-padding: #{(math.div($v-lg-rem, 2))}rem;
$v-lg-padding: #{$v-lg-rem * 1.5}rem;
$v-xl-padding: #{$v-lg-rem * 2}rem;

.content {
  @include clearfix;
  margin: 0 auto;
  max-width: $content-width;
  padding: 0 4rem;
  width: 100%;

  > *:last-child {
    margin-bottom: 0;
  }
}

section {
  contain-intrinsic-size: 1000px; // Total estimate
  content-visibility: auto;
  padding: $v-md-padding 0;

  &.home-header {
    padding-top: $v-xl-padding;
    h1 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 0;
    }

    > * {
      max-width: $content-width;
    }
  }

  &.text {
    max-width: $text-width;
    width: 100%;
  }
}

.collection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.full-bleed-hero {
  position: relative;

  .full-bleed-scrim {
    z-index: 99;
    position: absolute;
    background: linear-gradient(
      -53deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.35) 100%
    );
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  h1 {
    bottom: 4rem;
    color: white;
    position: absolute;
    right: 4rem;
    left: 8rem;
    text-align: right;
    z-index: 100;
    font-size: 8rem;
    line-height: 10rem;

    span {
      display: block;
      float: right;
      direction: rtl;
    }
  }
}

article:not(.with-full-width-image) .text:first-child {
  margin-top: -6rem;
}

article:not(.with-full-width-image) .hero-image + .text {
  margin-top: -3rem;
}

@media screen and (max-width: $desktop-max) {
  .full-bleed-hero h1 {
    font-size: 6rem;
    line-height: 7.5rem;
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .full-bleed-hero h1 {
    font-size: 5rem;
    line-height: 6rem;
    left: 4rem;
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .full-bleed-hero h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  section {
    padding: 3rem 0;

    &.home-header {
      padding-top: $v-lg-padding;
    }
  }

  .content {
    padding: 0 1rem;
  }

  .text.hero-image {
    margin: -4rem 0 0;
  }

  .full-bleed-hero h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
}