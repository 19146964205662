figure + .image-set {
  margin-top: -2.5rem;
}

.image-set {
  margin: 3rem -0.5rem 0;

  &.inline {
    margin-bottom: 3rem;
  }

  &.tight {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
  }

  .large,
  .small,
  .xl {
    align-items: flex-start;
    display: flex;
    font-size: 0;
    margin-bottom: 1.5rem;
    width: 100%;

    figure {
      margin: 0 0.75rem;
      width: 50%;
    }
  }

  .large figure {
    width: 50%;
  }

  .small figure {
    width: 33.333%;
  }

  .xl figure {
    width: 100%;
  }

  + h5 {
    margin-bottom: 4rem;
    padding: 0 2rem;
    text-align: center;
  }

  .figure-placeholder {
    background-color: $light-gray;

    a {
      display: block;
      height: 0;
      padding-bottom: 75%;
      position: relative;
      width: 100%;
    }

    i {
      background-position: center;
      background-size: cover;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .image-set {
    margin-top: 3rem;

    .large,
    .small,
    .xl {
      margin-bottom: 1rem;

      figure {
        margin: 0 0.5rem;
      }
    }

    + h5 {
      margin-bottom: 3rem;
      padding: 0;
      text-align: left;
    }
  }
}