body {
  .scrim {
    background-color: $white;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.3s ease opacity;
    z-index: 500;
  }

  &[class*="viewer-"] .scrim {
    cursor: zoom-out;
    opacity: 1;
    pointer-events: auto;
  }

  &.show-image-links {
    .progressive-image .self-link {
      display: flex;
    }
  }
}

.progressive-image, .progressive-link {
  user-select: none;
}

.progressive-image {
  overflow: hidden;
  position: relative;
  transform-origin: 0 0;
  transition: 0.3s ease transform;

  .aspect-ratio-holder {
    height: 0;
    width: 100%;
  }

  &.tight {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
  }

  &.inline {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  &.with-viewer {
    cursor: zoom-in;

    &.is-open {
      cursor: zoom-out;

      .self-link {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .self-link {
    @extend %h5;
    align-items: center;
    bottom: 1rem;
    display: none;
    left: 1rem;
    position: absolute;
    transition: 0.3s ease opacity;
    z-index: 100;

    i {
      align-items: center;
      background-color: $white;
      border-radius: 50%;
      box-sizing: content-box;
      display: flex;
      padding: 0.675rem;
    }

    label {
      background-color: $white;
      color: $black;
      font-size: 0.875rem;
      left: 100%;
      margin-left: 0.5rem;
      opacity: 0;
      padding: 0 0.5rem;
      position: absolute;
      transform: translate3d(0, 1rem, 0);
      transition: 0.3s ease all;
      white-space: nowrap;
    }

    &:hover {
      label {
        opacity: 1;
        transform: none;
      }
    }

    svg {
      width: 0.75rem;

      path {
        fill: $black;
      }
    }
  }

  img {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s ease opacity;
    z-index: 2;

    &.thumbnail {
      filter: blur(30px);
      opacity: 1;
      z-index: 1;
    }
  }

  &.loaded {
    img {
      opacity: 1;

      &.thumbnail {
        opacity: 0;
      }
    }

    .raw {
      opacity: 0;
    }
  }

  &.loaded-raw {
    img {
      transition: none;

      &.raw {
        opacity: 1;
      }

      &.thumbnail, &.full {
        opacity: 0;
      }
    }
  }
}